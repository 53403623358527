/*
 * Servizio che si occupa della gestione del Session Storage. Nativamente, l'oggetto Session Storage espone i seguenti metodi:
 * getItem(key), setItem(key, value), removeItem(key), key(position), clear(), length()
*/
module SessionStorageService {
    let app = angular.module("app");

    app.factory("SessionStorageService", ["$resource", "$sessionStorage", ($resource: ng.resource.IResourceService, $sessionStorage: any) => {
        const SEARCHES = "SEARCHES";
        let serv = {
            // Aggiunge un elemento chiave - valore
            set: (itemKey: string, value: Array<string>) => {
                $sessionStorage[itemKey] = value;
            },
            // Recupera un elemento chiave-valore
            get: (itemKey: string): Array<string> => {
                return $sessionStorage[itemKey];
            },
            // Conta gli elementi salvati
            count: (customSearchText?: string) => {
                let searches: Array<string> = serv.get(customSearchText || SEARCHES);
                return (searches && searches.length) || 0;
            },
            // Rimuove un elemento
            remove: (itemKey: number, customSearchText?: string) => {
                let searches: Array<string> = serv.get(customSearchText || SEARCHES);
                if(searches && searches.length > itemKey){
                    searches.splice(itemKey, 1);
                }
            },
            // Pulisce tutti gli elementi. Da usare con cautela poichè nella sessione è salvato anche il token
            empty: (customSearchText?: string) => {
                $sessionStorage[customSearchText || SEARCHES] = null;
            },
            // Pulisce tutti gli elementi partendo da un indice specifico
            clearFrom: (deleteFrom: number, deleteTo: number) => {
                // DeleteFrom è l'indice attuale (parametro searchId dello state), quindi lo incremento affinchè elimini i dati dal risultato successivo in poi
                deleteTo--;
                while(deleteTo > deleteFrom) {
                    serv.remove(deleteTo);
                    deleteTo--;
                }                
            },
            // Salva un elemento chiave - oggetto
            saveObject: (itemKey: string, obj: any, customSearchText?: string) => {
                let idx = parseInt(itemKey, 10);
                if (typeof obj === 'object' && !isNaN(idx) && idx >= 0) {
                    // Recupero l'array dalla sessione
                    let searches: Array<string> = serv.get(customSearchText || SEARCHES);
                    // Se non esiste, lo creo
                    if (!searches) {
                        searches = [];
                    }
                    // Aggiungo l'elemento
                    searches[idx > 0 ? idx - 1 : 0] = JSON.stringify(obj); // Inserisco il valore -1 perché l'array è 0-based
                    // Salvo l'array in sessione
                    serv.set(customSearchText || SEARCHES, searches)
                }
            },
            // Recupera un elemento chiave - oggetto
            getObject: (itemKey: string, customSearchText?: string) => {
                let searches: Array<string> = serv.get(customSearchText || SEARCHES);
                let idx = parseInt(itemKey, 10);
                if(searches && searches.length && !isNaN(idx) && idx >= 0 && searches.length > idx - 1){
                    return JSON.parse(searches[idx - 1]); // Recupero il valore -1 perché l'array è 0-based
                }
            }
        };
        return serv;
    }]);
}